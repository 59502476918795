/*
|==========================================================================
| Sticky Header
|==========================================================================
| 
| This function gives us the option to implement a sticky header, with
| custom options for class, height, and calculation.
|
|==========================================================================
|
*/

function check_size() {

    var scroll_pos = jQuery(window).scrollTop();

    if (jQuery(window).width() < 1200) {
        jQuery('header.header').removeClass('mini');
        jQuery('.sticky-sidebar').css('transform', 'translateY(0)');
    } else {

        if (scroll_pos >= 100) {
            jQuery('.sticky-sidebar').css('transform', 'translateY(-3.2rem)');
            jQuery('header.header').addClass('mini');
            jQuery('.sub-nav.open').each(function() {
                jQuery(this).removeClass('open').attr('aria-hidden','true').attr('aria-expanded','false');
            });

        } else {
            jQuery('header.header').removeClass('mini');
            jQuery('.sticky-sidebar').css('transform', 'translateY(0)');
        }
    }
}

function sticky_header() {

    check_size();

    jQuery(window).on('resize', function() {
        check_size();
    });
} 