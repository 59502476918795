// Prepends progress bar to DOM
function celiac_progress_bar() {

	// console.log('Appending Progress Bar...'); 

	var progress_bar_target = jQuery("#main");
	var progress_bar = ".celiac-progressbar";

	if(jQuery(progress_bar).length === 0) {
		progress_bar_target.addClass('relative'); 
		progress_bar_target.prepend('<div class="celiac-progressbar progress flex flex-row justify-center items-center" role="progressbar" tabindex="0" aria-valuenow="50" aria-valuemin="0" aria-valuetext="50 percent" aria-valuemax="100"><span class="start track-label ph2 ph3-l f4-l fw8">Start</span><span class="progress-meter-track"><span class="progress-meter" style="width: 0%;"><p class="progress-meter-text"></p></span></span><span class="finish track-label ph2 ph3-l f4-l fw8">Finish</span></div>'); // Append to main area
	}

}

// Calculates progress based on form
function celiac_calc_progress(progress_form) { 

	var progress_bar		= ".celiac-progressbar";
	var progress_bar_meter	= jQuery(progress_bar + " .progress-meter");
	var progress_bar_text	= jQuery(progress_bar + " .progress-meter .progress-meter-text");

	var completed_fields	= 0;
	var percent_complete	= 0;

	var total_fields;
	var percent_complete_fixed;

	// If iCure

	if(progress_form === 'form[action^="/cdf-question/survey/"]') {

		// console.log('Calculate progress iCure');

		var icure_active_questions		= jQuery('.fhir-Questionnaire-Wizard > .nav > .nav-link').not('.disabled');
		var total_visible_fields		= icure_active_questions.length;

		icure_active_questions.each(function(i) {
			
			var tab_id = jQuery(this).attr('href');
			var tab_element = jQuery(tab_id);

			if(tab_element.hasClass('type-choice') && tab_element.hasClass('has-value')) {
				
				++completed_fields;

			}

			if(tab_element.hasClass('type-group')) {

				var form_groups = tab_element.find('.form-group');
				form_groups.each(function(i) {

					var form_group = jQuery(this);
					var input_type_checkbox = form_group.find(':checkbox:checked');
					var input_type_radio = form_group.find(':radio:checked');
					
					if(input_type_checkbox.length > 0) {
						++completed_fields;
					}
					if(input_type_radio.length > 0) {
						++completed_fields;
					}
				
				});

			} // type-group

			if(tab_element.hasClass('type-string')) {

				var input_type_text = tab_element.find('input[type="text"]');
				var input_type_text_val = input_type_text.val();

				// console.log(input_type_text);
				// console.log(input_type_text_val);

				if(input_type_text_val) {
					if(input_type_text_val.length >= 3) {
						++completed_fields;
					}
				}

			}

			if(tab_element.hasClass('type-text')) {

				var input_type_text = tab_element.find('textarea');
				var input_type_text_val = input_type_text.val();

				// console.log(input_type_text);
				// console.log(input_type_text_val);

				if(input_type_text_val) {
					if(input_type_text_val.length >= 3) {
						++completed_fields;
					}
				}

			}

		
		});

	}

	// If Symptoms Assessment Tool

	if(progress_form === "#gform_1") {

		// console.log('Calculate progress Symptoms Assessment Tool');

		var form_input_list			= jQuery(progress_form + " .gform_fields > li:not(.no-buttons)");
		var total_visible_fields 	= form_input_list.length;

		var none_of_the_above_find 	= ':checkbox[value="None of the above"]';
		var none_of_the_above 		= jQuery(form_input_list).find(none_of_the_above_find);

		var all_other_fields_find 	= ':checkbox[value!="None of the above"]';
		var all_other_fields 		= jQuery(form_input_list).find(all_other_fields_find);

		var symptom_count_find 		= jQuery(progress_form + " .gform_fields > li.symptom").find(':checkbox:checked[value!="None of the above"]');
		
		var family_members			= jQuery(form_input_list).find('#choice_1_62_0');
		var family_symptoms;
		if(family_members.is(':checked')) { 
			family_symptoms = true;
		 } else {
			family_symptoms = false;
		 }

		console.log('Family Members', family_members.is(':checked'), family_symptoms); 

		// Loop through each item list, if selection is made, add one
		form_input_list.each(function(i) { 
			var input_type_checkbox = jQuery(this).find(':checkbox:checked');
			var input_type_radio = jQuery(this).find(':radio:checked');
			var continue_button = jQuery(this).find('.symptoms-assessment-tool-continue');
			if(input_type_checkbox.length > 0) {
				++completed_fields;
				continue_button.removeAttr('disabled');
			}
			if(input_type_radio.length > 0) {
				++completed_fields;
				continue_button.removeAttr('disabled');
			}
			if(input_type_checkbox.length === 0 && input_type_radio.length === 0) {
				continue_button.attr('disabled','');
			}
		});

		// If we have symptoms
		var symptoms_field_ul_id = " #input_1_79 ";
		var response_list = jQuery("#field_1_82 .symptoms-assessment-tool-response-list");
		
		if(completed_fields > 0) {

			if(symptom_count_find.length > 0 || family_symptoms === true) {			

				console.log('Symptoms Detected');
				document.querySelector('#choice_1_79_0').setAttribute('checked', 'checked');
				document.querySelector('#choice_1_79_1').removeAttribute('checked');
				document.getElementById("choice_1_79_0").click();

				// Hide negative reponse and show positive response
				jQuery('.response-no').css('display', 'none');
				jQuery('.response-yes').css('display', 'block');

			} else {

				console.log('No Symptoms Detected');
				document.querySelector('#choice_1_79_0').removeAttribute('checked');
				document.querySelector('#choice_1_79_1').setAttribute('checked', 'checked');
				document.getElementById("choice_1_79_1").click();

				// Hide positive reponse and show negative response
				jQuery('.response-yes').css('display', 'none');
				jQuery('.response-no').css('display', 'block');

			}

		}

		none_of_the_above.change(function() {
    		if(this.checked) {
				jQuery(this).parent().parent().find(all_other_fields_find).prop('checked', false);
    		}
    	});

		all_other_fields.change(function() {
    		if(this.checked) {
				jQuery(this).parent().parent().find(none_of_the_above_find).prop('checked', false);
    		}
    	});

	}

	percent_complete = parseFloat((completed_fields / total_visible_fields) * 100); // Float

	percent_complete_fixed = percent_complete.toFixed(2); // String

	// console.log('Total: '+total_fields);
	// console.log('Total Visible: '+total_visible_fields);
	// console.log('Completed: '+completed_fields);
	// console.log('%: '+percent_complete_fixed);

	progress_bar_text.text(percent_complete.toFixed(0)+'%'); // String

	jQuery(progress_bar + " .progress-callout.chosen-msg").not('.hide').each(function(i){

		var this_message = jQuery(this); 
		var show_percent = this_message.attr('data-progress-callout-show');
		var hide_percent = this_message.attr('data-progress-callout-hide');
		var show_percent_int = Number(show_percent.replace('%',''));
		var hide_percent_int = Number(hide_percent.replace('%',''));

		var show = percent_complete >= show_percent_int;
		var hide = percent_complete <= hide_percent_int;

		// console.log(this_message);

		if(show === true && hide === true) {
			this_message.fadeIn();
		} else {
			this_message.fadeOut();
		} 

	});

	if(percent_complete_fixed === '100.00') {
		// console.log('Completed!');
		progress_bar_meter.parent().addClass('success');
		jQuery(progress_bar + " .progress-callout").fadeOut();
	} else {
		progress_bar_meter.parent().removeClass('success');
	}

	// Update the progress bar inline style
	progress_bar_meter.attr('style','width:'+percent_complete.toFixed(4)+'%; display:block;');

}