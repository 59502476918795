var icure_form_selector = 'form[action^="/cdf-question/survey/"]';
var icure_page = document.querySelector("body.icure");

if(icure_page != null) { 

	// Mutation Observer to Watch Change waiting for Angular App to Load
	var observerConfig, icureSectionObserver;

	observerConfig = {
		attributes: true,
		childList: true,
		characterData: true
	};

	icureSectionObserver = new MutationObserver(function (mutations) {
		mutations.forEach(function (mutation) {
			celiac_calc_progress(icure_form_selector);
		});
	});

	icureSectionObserver.observe(document.getElementById("main"), observerConfig);

}

function icure_experience() {

	// console.log('iCure');

	var icure_form_selector = 'form[action^="/cdf-question/survey/"]';
	var icure_form 			= jQuery(icure_form_selector);

	if(jQuery(icure_form).length > 0) {

		celiac_progress_bar();

		// Progress Bar messages
		// Move Alert Messages
		jQuery('.progress-callout').removeClass('hide').appendTo(".celiac-progressbar");
		jQuery('.progress-callout .close-button').click(function(e){
			jQuery(this).parent().addClass('hide');
		});

		// Calculate Progress Continuously to Account for Field Visibility
	    setInterval(function(){
			celiac_calc_progress(icure_form_selector);
	    },100);

		// Progress Bar Messages
		var progress_bar = ".celiac-progressbar";
		jQuery(progress_bar + " .progress-callout").each(function(i){

			var this_message = jQuery(this); 
			var show_percent = this_message.attr('data-progress-callout-show');
			var hide_percent = this_message.attr('data-progress-callout-hide');

			var attributes_to_match = '.progress-callout[data-progress-callout-show="'+show_percent+'"][data-progress-callout-hide="'+hide_percent+'"]';
			var same_progress_message = jQuery(progress_bar + ' ' + attributes_to_match);
			var multiple_message_count = same_progress_message.length;
			var random_element_index = Math.floor(Math.random() * same_progress_message.length); // 0 - 2

			// Add Class if none is selected
			if(jQuery(progress_bar + ' ' + attributes_to_match + '.chosen-msg').length === 0) {
				jQuery(progress_bar + ' ' + attributes_to_match + ':eq('+random_element_index+')').addClass('chosen-msg');
			}

		});

		/*

		icure_form.removeClass('at-end').addClass('not-at-end');

		// Set up question buttons
		var icure_form_questions = jQuery(icure_form_selector + ' .fhir-Questionnaire-item-choice, ' + icure_form_selector + ' .fhir-Questionnaire-item-string, ' + icure_form_selector + ' .fhir-Questionnaire-item-text,  ' + icure_form_selector + ' .fhir-Questionnaire-item-integer');
		var continue_button = '<button class="screen-continue button bg-orange br4 ph2 b expanded f3-l f5" disabled type="button">Continue <span class=" fa fa-arrow-circle-o-right"></span></button>';
		var previous_button = '<button class="screen-previous button orange bg-white br4 ph2 b ttu tracked tracked-mega-l expanded" type="button"><span class=" fa fa-arrow-circle-o-left"></span> Previous Question</button>';
		var question_buttons = '<div class="w-100"><div class="w-60 w-60-m w-50-l pv2 pr0 cf flex flex-wrap blue-4 bg-transparent btn_container clearboth">';
			question_buttons += '<div class="small-12">';
			question_buttons += continue_button;
			question_buttons += '</div>';
			question_buttons += '<div class="small-12">';
			question_buttons += previous_button;
			question_buttons += '</div>'; 
			question_buttons += '</div></div>';

		// Append buttons to each question
		jQuery(icure_form_questions).append(question_buttons);

		*/


	} else {
		// console.log('No iCure Form Found');
	}

	// Scroll to next closest question
	/*
	var icure_form_input = jQuery(icure_form_selector + " :input:not(textarea, input:text)"); 

	jQuery(icure_form_input).on('click', function(e) { 

		var el = jQuery( this ).closest('.fhir-Questionnaire-item:visible').nextAll(".fhir-Questionnaire-item").not(".d-none").first();
	
		if(el && el.offset() != undefined) { 
		
			var elOffset = el.offset().top;
			var elHeight = el.height();
			var windowHeight = jQuery(window).height();
			var offset;

			if (elHeight < windowHeight) {
				offset = elOffset - ((windowHeight / 2) - (elHeight / 2));
			}
			else {
				offset = elOffset;
			}
			var speed = 650;
			jQuery('html, body').animate({scrollTop:offset}, speed);
		
		}

	});
	*/

}