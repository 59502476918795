/*
|==========================================================================
| WordPress + Foundation
|==========================================================================
|
| These functions make sure WordPress and Foundation play nice together,
| and this is also where we declare functions defined in this folder.
|
|==========================================================================
|
*/

jQuery(document).ready(function ($) {

  // Evolving function for WCAG guidelines
  v8_wcag();

  // Detect Internet Explorer
  detect_IE();

  // Detect Native Android Browser
  detect_native_android_browser();

  // Apple Device Detect
  apple_device_detect();

  force_smart_quotes(document.body);

  var print_icon = document.getElementById("print-icon");

  if (print_icon !== null) {
    document.getElementById("print-icon").onclick = function (event) { window.print(); };
  }

  // Enable AOS for use out of the box
  AOS.init({
    disable: 'mobile'
  });

  if (jQuery('.footnote').length > 0) {
    jQuery('#footnotes').addClass('db');
  };

  jQuery('#main p').each(function () {
    var $p = jQuery(this),
      txt = $p.html();
    if (txt == '&nbsp;') {
      $p.remove();
    }
  });

  responsive_tables();

  jQuery(".footnote-list-item").each(function (index) {
    jQuery(this).attr('id', 'footnote-' + ++index);
  });
  // console.log(email_trigger);

  function populateEmailModal() {

    var email_trigger = document.querySelector('[data-micromodal-trigger="email-modal"]');

    email_trigger.addEventListener("click", function () {

      console.log('yes');

      var emailModal = document.getElementById("email-modal");
      var emailContainer = emailModal.firstElementChild.firstElementChild.children[1];

      var salsaEmail = "https://default.salsalabs.org/api/widget/template/de575343-e312-47c3-a7a7-eea41f4a8bb2/?tId=31325a16-aace-4f9a-bd36-31e9169f751b";

      var modalDiv = document.createElement("div");
      modalDiv.setAttribute("id", "31325a16-aace-4f9a-bd36-31e9169f751b");

      var modalScript = document.createElement("script");
      modalScript.setAttribute("src", salsaEmail);

      modalDiv.appendChild(modalScript);
      emailContainer.appendChild(modalDiv);

      // console.log('clicked');

    });

  }


  // console.log(emailContainer);


  // MicroModal.init();

  MicroModal.init({
    disableScroll: true,
    onShow: function (modal) {

      var email_form = document.getElementById("31325a16-aace-4f9a-bd36-31e9169f751b");

      if (email_form == null) {
        var emailModal = document.getElementById("email-modal");
        var emailContainer = emailModal.firstElementChild.firstElementChild.children[1];

        var salsaEmail = "https://default.salsalabs.org/api/widget/template/de575343-e312-47c3-a7a7-eea41f4a8bb2/?tId=31325a16-aace-4f9a-bd36-31e9169f751b";

        var modalDiv = document.createElement("div");
        modalDiv.setAttribute("id", "31325a16-aace-4f9a-bd36-31e9169f751b");

        var modalScript = document.createElement("script");
        modalScript.setAttribute("src", salsaEmail);

        modalDiv.appendChild(modalScript);
        emailContainer.appendChild(modalDiv);
      } else {

      }

    },
    awaitCloseAnimation: true
  });

  var search_icon = document.getElementById('search-toggle');
  var search_field = document.getElementById('search-field0');

  search_icon.onclick = function (event) {
    search_field.focus();
  };


  // Force focus input search field for tablet
  jQuery('#search-dropdown-tablet').on('show.zf.dropdown', function () {

    var dropdown = jQuery(this);

    setTimeout(function () {

      dropdown.find('[autofocus]').focus();
      console.log('focus');

    }, 600);

  });


  // jQuery(window).on('resize', function() {
  //   responsive_tables();
  // });

  // Gravity Forms Placeholder Clearing
  // gravity_forms_placeholders();

  // Inline SVG replacement
  // inline_svg_replacement();

  // Isotope Masonry
  // isotope_masonry();

  // TablePress Cleanup
  // tablepress();

  // Event Target Testing
  // jQuery(document).click(function(event) {

  // });

  // Remove empty P tags created by WP inside of Accordion and Orbit
  jQuery('.accordion p:empty, .orbit p:empty').remove();

  // Makes sure last grid item floats left
  jQuery('.archive-grid .columns').last().addClass('end');

  // Adds Flex Video to YouTube and Vimeo Embeds
  // jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
  //   if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
  //     jQuery(this).wrap("<div class='widescreen flex-video'/>");
  //   } else {
  //     jQuery(this).wrap("<div class='flex-video'/>");
  //   }
  // });


  jQuery('.off-canvas-wrapper').css({
    "overflow": "hidden"
  });

  setTimeout(function () {

    jQuery('.off-canvas-wrapper').css({
      "overflow": "inherit"
    });

  }, 2000);


});

// Home Page: Tweet Display
var configCeliac = {
  "profile": { "screenName": 'CeliacDotOrg' },
  "domId": 'cdf-tweet',
  "maxTweets": 1,
  "enableLinks": false,
  "showUser": false,
  "showInteraction": false,
  "showTime": false,
  "showImages": false,
  "lang": 'en'
};
// if(jQuery("#cdf-tweet").length > 0) {
//   twitterFetcher.fetch(configCeliac);
// }

// Home Page: Our Sponsors
// console.log(jQuery(".owl-carousel"));
if (jQuery(".owl").length > 0) {
  jQuery(".owl").owlCarousel({
    items: 9,
    loop: true,
    dots: false,
    margin: 20,
    merge: true,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: false
      },
      640: {
        items: 4,
        nav: false
      },
      767: {
        items: 6,
        nav: false
      },
      1024: {
        center: true,
        items: 7,
        nav: false
      },
      1200: {
        items: 9,
        nav: false
      }
    }
  });
}

// iCure
// if(jQuery("body.icure").length > 0) {
// console.log('iCure');
// icure_experience();
// }

// iCure Plyr

// Change "{}" to your options:
// https://github.com/sampotts/plyr/#options
const player = new Plyr('#player', {});

// Expose player so it can be used from the console
window.player = player;


// Symptoms Assessment Tool
if (jQuery("body.page-template-template-symptoms-assessment-tool").length > 0) {
  console.log('Symptoms Assessment Tool');
  symptoms_assessment_tool();
}
