function responsive_tables() {

	var i;
	var j;

	var headers = jQuery('table.celiac-mobile th');
	// console.log(headers);
	// console.log(headers.length);

	for(i = 1, j = 1; i <= headers.length; i++, j+=2) {

		var header = jQuery('table.celiac-mobile th:nth-child(' + i + ')');

		jQuery('table.celiac-mobile tr td:nth-child(' + j + ')').each(function(index) {
			jQuery(this).before("<td class='mh subheader ttu tracked ph3 grey b mv0 bg-black-10 mobile-header" + "-" + i + "'>" + header.text() + "</td>");
		});
	}

}