const symptoms_assessment_tool_template = ".page-template-template-symptoms-assessment-tool";
const symptoms_assessment_tool_form_id = "#gform_1";
const symptoms_assessment_tool_form = symptoms_assessment_tool_template + ' ' + symptoms_assessment_tool_form_id;

function symptoms_assessment_tool_scroll_up() {

	setTimeout(function(){
		AOS.refresh();
	},301);
	if (jQuery(window).width() <= 768) {
		jQuery('html,body').animate({
			scrollTop: jQuery("#main").offset().top
		},1);
	} else {
		jQuery('html,body').animate({
			scrollTop: jQuery('#main').offset().top - 132
		},1);
	}

}

function symptoms_assessment_tool() {

	jQuery(symptoms_assessment_tool_form).removeClass('at-end').addClass('not-at-end');

	// Set up intro screen
	var intro_elements = jQuery(symptoms_assessment_tool_template + ' #main .lead-paragraph, ' + symptoms_assessment_tool_template + ' #main .section-with-content');
	jQuery(intro_elements).insertAfter(symptoms_assessment_tool_template + " .form-begin .page-header");

	// Set up question buttons
	var gravity_form_questions = jQuery(symptoms_assessment_tool_form + ' .gform_fields > li:not(.no-buttons)');
	var continue_button = '<button class="symptoms-assessment-tool-continue button bg-orange br4 ph2 b expanded f3-l f5" disabled type="button">Continue <span class=" fa fa-arrow-circle-o-right"></span></button>';
	var previous_button = '<button class="symptoms-assessment-tool-previous button orange bg-white br4 ph2 b ttu tracked tracked-mega-l expanded" type="button"><span class=" fa fa-arrow-circle-o-left"></span> Previous Question</button>';
	var question_buttons = '<div class="w-60 w-60-m w-50-l pv2 pr0 cf flex flex-wrap blue-4 bg-transparent btn_container">';
		question_buttons += '<div class="small-12">';
		question_buttons += continue_button;
		question_buttons += '</div>';
		question_buttons += '<div class="small-12">';
		question_buttons += previous_button;
		question_buttons += '</div>';
		question_buttons += '</div>';

	// Append buttons to each question
	jQuery(gravity_form_questions).append(question_buttons);

	// Set up finish buttons
	var gravity_form_finish_footer = jQuery(symptoms_assessment_tool_form + ' .gform_footer');
	var previous_button_finish = '<button class="symptoms-assessment-tool-previous-finish button orange bg-white br4 ph2 b ttu tracked tracked-mega-l expanded" type="button"><span class=" fa fa-arrow-circle-o-left"></span> Previous Question</button>';

	jQuery(gravity_form_finish_footer).addClass('w-60 w-60-m w-50-l pv2 pr0 cf flex flex-wrap blue-4 bg-transparent btn_container');
	jQuery(gravity_form_finish_footer).append(previous_button_finish);

	// Append Previous Button to Receive Referral Letter
	jQuery("#field_1_78").append(previous_button_finish);

	// Set up tooltips
	var tooltips = jQuery(symptoms_assessment_tool_form + " tooltip");

	if(tooltips.length > 0) {

		tooltips.each(function(i){
			var tooltip_text = jQuery(this).text();
			var tooltip_text_clean = tooltip_text.replace(' - ','');
			jQuery(this).text(tooltip_text_clean);
			jQuery(this).wrap('<a href="#" class="tooltip-trigger ml3 orange dib"></a>');
			jQuery('<span class="fa fa-info-circle"></span>').insertBefore( jQuery(this) );
		});

		// Once elements are created, bind events
		var tooltip_trigger = jQuery(symptoms_assessment_tool_form + " .tooltip-trigger");
		tooltip_trigger.click(function(e){
			e.preventDefault();
			e.stopPropagation();
		});

	}

	// Set up Progress Bar and Calculation
	let symptoms_assessment_tool_form_input = jQuery(symptoms_assessment_tool_form_id + " :input");

	if(jQuery(symptoms_assessment_tool_form).length > 0) {

		celiac_progress_bar();

		// Calculate Progress On Change
		celiac_calc_progress(symptoms_assessment_tool_form_id);
		jQuery(symptoms_assessment_tool_form_input).change(function(e) {
			celiac_calc_progress(symptoms_assessment_tool_form_id);
			e.stopPropagation();
		});

	} else {
		// console.log('No Symptoms Assessment Tool Form Found');
	}

	// Buttons
	jQuery('#begin-symptoms-assessment-tool').click(function(e){

		// Append AOS
		jQuery(symptoms_assessment_tool_form + ' .gform_fields > li').not('.gfield_visibility_hidden').attr('data-aos','fade-left').attr('data-aos-anchor-placement','top-bottom').attr('data-aos-duration','750');

		jQuery('.form-begin').hide();
		var first_question = jQuery(symptoms_assessment_tool_form + ' ul li:first-child');

		first_question.removeClass('visible').removeClass('aos-animate');
		setTimeout(function(){
			first_question.addClass('visible');
			setTimeout(function(){
				first_question.addClass('aos-animate');
			},150);
		},150);

		symptoms_assessment_tool_scroll_up();

		e.preventDefault();
		e.stopPropagation();
	});

	// Continue Buttons
	var continue_button_selector 	= '.symptoms-assessment-tool-continue';
	var continue_button_count 		= jQuery(continue_button_selector).length - 1;

	jQuery(continue_button_selector).each(function(i){
		jQuery(this).attr('data-button-index',i);
	});

	jQuery(continue_button_selector).click(function(e){

		// Append AOS
		jQuery(symptoms_assessment_tool_template + ' .gform_fields > li').attr('data-aos','fade-left').attr('data-aos-anchor-placement','top-bottom').attr('data-aos-duration','750');

		var this_button = jQuery(this);
		var data_button_index = parseInt(this_button.attr('data-button-index'));

		jQuery('.form-begin').hide().removeClass('aos-animate');

		// Hide other questions
		jQuery(symptoms_assessment_tool_form + ' ul li').removeClass('visible').removeClass('aos-animate');

		// Reveal next question
		var next_question = this_button.closest('li.gfield').next();
		setTimeout(function(){
			next_question.addClass('visible');
			setTimeout(function(){
				next_question.addClass('aos-animate');
			},150);
		},150);

		// Show final fields at end
		var final_fields = jQuery(symptoms_assessment_tool_form + ' .finish');
		if(data_button_index === continue_button_count) {

			jQuery(symptoms_assessment_tool_form).removeClass('not-at-end').addClass('at-end');
			setTimeout(function(){
				final_fields.addClass('visible');
				setTimeout(function(){
					final_fields.addClass('aos-animate');
				},150);
			},150);

			// Show referral letter
			showReferralLetterInput();

		} else {
			final_fields.removeClass('visible aos-animate');
			jQuery(symptoms_assessment_tool_form).removeClass('at-end').addClass('not-at-end');
		}

		symptoms_assessment_tool_scroll_up();

		e.preventDefault();
		e.stopPropagation();
	});

	// Previous Buttons
	var previous_button_selector 	= '.symptoms-assessment-tool-previous';
	var previous_button_count 		= jQuery(previous_button_selector).length - 1;

	jQuery(previous_button_selector).each(function(i){
		jQuery(this).attr('data-button-index',i);
	});

	jQuery(previous_button_selector).click(function(e){

		// Append AOS
		jQuery(symptoms_assessment_tool_form + ' .gform_fields > li').attr('data-aos','fade-right').attr('data-aos-anchor-placement','top-bottom').attr('data-aos-duration','750');

		jQuery(symptoms_assessment_tool_form).removeClass('at-end').addClass('not-at-end');

		var this_button = jQuery(this);
		var data_button_index = parseInt(this_button.attr('data-button-index'));

		// Show intro at beginning
		if(data_button_index === 0) {
			jQuery('.form-begin').hide().removeClass('aos-animate').show().addClass('aos-animate');
		} else {
			jQuery('.form-begin').hide().removeClass('aos-animate');
		}

		// Hide other questions
		jQuery(symptoms_assessment_tool_form + ' ul li').removeClass('visible').removeClass('aos-animate');

		// Reveal previous question
		var previous_question = this_button.closest('li.gfield').prev();
		setTimeout(function(){
			previous_question.addClass('visible');
			setTimeout(function(){
				previous_question.addClass('aos-animate');
			},150);
		},150);

		symptoms_assessment_tool_scroll_up();

		e.preventDefault();
		e.stopPropagation();
	});

	// Previous Button Finish
	jQuery('.symptoms-assessment-tool-previous-finish').click(function(e){

		jQuery(symptoms_assessment_tool_form + ' .gform_fields > li').not('.gfield_visibility_hidden').attr('data-aos','fade-right').attr('data-aos-anchor-placement','top-bottom').attr('data-aos-duration','750');

		jQuery(symptoms_assessment_tool_form).removeClass('at-end').addClass('not-at-end');

		var this_button = jQuery(this);

		// Hide other questions
		jQuery(symptoms_assessment_tool_form).removeClass('at-end').addClass('not-at-end');
		jQuery(symptoms_assessment_tool_form + ' .finish').not('.response').hide();
		jQuery(symptoms_assessment_tool_form + ' .gform_footer').hide();

		// Reveal next question

		symptoms_assessment_tool_scroll_up();

		var prev_question = jQuery(symptoms_assessment_tool_form).find('li.gfield').not('.finish').last();
		setTimeout(function(){
			prev_question.addClass('visible');
			setTimeout(function(){
				prev_question.addClass('aos-animate');
			},150);
		},150);

		e.preventDefault();
		e.stopPropagation();
	});

	salsa_form_mapping();

	// Set Assessment Date from Client Side
	var d = new Date();
	var n = formatAMPM(d);
	jQuery("#input_1_83").val(n);

}

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

function titleCase(str) {
	str = str.toLowerCase().split(' ');
	for (var i = 0; i < str.length; i++) {
	  str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
	}
	return str.join(' ');
  }

function showReferralLetterInput() {
	jQuery(symptoms_assessment_tool_form + ' .gform_footer').show();
	jQuery(symptoms_assessment_tool_form + ' .finish.referral-letter').show();
	setTimeout(function(){
		jQuery('#field_1_56, #field_1_57').find('input').removeAttr('disabled');
		jQuery('html,body').animate({
			scrollTop: jQuery('#referral-letter-request').offset().top - 132
		},1000);
	},250);
}

// Function to Map Gravity Form Fields to Salsa Form Fields

function salsa_form_mapping() {

	// Prepare selector string...

	// Page template
	var pt = '.page-template-template-symptoms-assessment-tool';

	// Gravity Form ID
	var gf = pt + ' #gform_1';

	// Salsa Form ID
	var sf = pt + ' #9226c3be-4860-4c3d-b878-77f9d39ebe89';

	// Now map to fields...

	if(jQuery(sf).children().length > 0) {
		// console.log('Salsa Form Loaded...');
		// console.log('Mapping to Salsa Form...');
	}

	// First Name
	var gf_firstname_field = gf + ' #input_1_56_3';
	var salsa_firstname_field = sf + ' input.sli-input-person-firstname';

	jQuery(gf_firstname_field).on('keyup keypress', function(e) {
		var this_value = jQuery(this).val();
		if (e.type=="keyup") {
			jQuery(salsa_firstname_field).val(this_value);
		} else {
			jQuery(salsa_firstname_field).val(this_value);
		}
	});

	// Last Name
	var gf_lastname_field = gf + ' #input_1_56_6';
	var salsa_lastname_field = sf + ' input.sli-input-person-lastname';

	jQuery(gf_lastname_field).on('keyup keypress', function(e) {
		var this_value = jQuery(this).val();
		if (e.type=="keyup") {
			jQuery(salsa_lastname_field).val(this_value);
		} else {
			jQuery(salsa_lastname_field).val(this_value);
		}
	});

	// Email
	var gf_email_field = gf + ' #input_1_57';
	var salsa_email_field = sf + ' input.sli-input-contact-email';

	jQuery(gf_email_field).on('keyup keypress', function(e) {
		var this_value = jQuery(this).val();
		if (e.type=="keyup") {
			jQuery(salsa_email_field).val(this_value);
		} else {
			jQuery(salsa_email_field).val(this_value);
		}
	});

	// Address 1
	var gf_address_1 = gf + ' #input_1_84_1';
	var salsa_address_line_1 = sf + ' input.sli-input-address-line1';

	jQuery(gf_address_1).on('keyup keypress', function(e) {
		var this_value = jQuery(this).val();
		if (e.type=="keyup") {
			jQuery(salsa_address_line_1).val(this_value);
		} else {
			jQuery(salsa_address_line_1).val(this_value);
		}
	});

	// Address 2
	var gf_address_2 = gf + ' #input_1_84_2';
	var salsa_address_line_2 = sf + ' input.sli-input-address-line2';

	jQuery(gf_address_2).on('keyup keypress', function(e) {
		var this_value = jQuery(this).val();
		if (e.type=="keyup") {
			jQuery(salsa_address_line_2).val(this_value);
		} else {
			jQuery(salsa_address_line_2).val(this_value);
		}
	});

	// City
	var gf_address_city = gf + ' #input_1_84_3';
	var salsa_address_city = sf + ' input.sli-input-address-city';

	jQuery(gf_address_city).on('keyup keypress', function(e) {
		var this_value = jQuery(this).val();
		if (e.type=="keyup") {
			jQuery(salsa_address_city).val(this_value);
		} else {
			jQuery(salsa_address_city).val(this_value);
		}
	});

	// Zip
	var gf_zip_field = gf + ' #input_1_60_5';
	var salsa_zip_field = sf + ' input.sli-input-address-zip';

	jQuery(gf_zip_field).on('keyup keypress', function(e) {
		var this_value = jQuery(this).val();
		if (e.type=="keyup") {
			jQuery(salsa_zip_field).val(this_value);
		} else {
			jQuery(salsa_zip_field).val(this_value);
		}
	});

	// State
	var gf_state_field = gf + ' #input_1_84_4';
	var salsa_state_field = sf + ' select.sli-select-address-state';

	// Long Country Name mapped to Salsa field which uses country
	// abbreviations as values, but long names in text label, using contains() css selector
	jQuery(gf_state_field).on('change', function(e) {
		var this_value = jQuery(this).val();
		console.log(this_value);
		jQuery(salsa_state_field + " option").prop('selected', false);

		// Abbreviation
		var abrSelector = salsa_state_field + ' option[value="' + this_value.toUpperCase() + '"]';
		if (jQuery(abrSelector).length > 0) {
			var option_filtered = jQuery(abrSelector);
			option_filtered.prop('selected', true);
		}

		// Text Input
		var txtSelector = salsa_state_field + ' option:contains("' + titleCase(this_value) + '")';
		if (jQuery(txtSelector).length > 0) {
			var option_filtered = jQuery(txtSelector);
			option_filtered.prop('selected', true);
		}
	});

	// Country
	var gf_country_field = gf + ' #input_1_58_6';
	var salsa_country_field = sf + ' select.sli-select-address-country';

	// Long Country Name mapped to Salsa field which uses country
	// abbreviations as values, but long names in text label, using contains() css selector
	jQuery(gf_country_field).on('change', function(e) {
		var this_value = jQuery(this).val();
		console.log(this_value);
		jQuery(salsa_country_field + " option").prop('selected', false);
		var option_filtered = jQuery(salsa_country_field + ' option[value="' + this_value + '"]');
		option_filtered.prop('selected', true);
	});

	// Newsletter field
	var gf_newsletter_field = gf + ' #choice_1_59_1';
	var salsa_newsletter_field = sf + ' input[name="contactOptIn"]';

	jQuery(gf_newsletter_field).change(function() {
		if(this.checked) {
			jQuery(salsa_newsletter_field).prop('checked', true);
		} else {
			jQuery(salsa_newsletter_field).prop('checked', false);
		}
	});



	// Submit handler for Salsa Form
	jQuery(gf + ' #gform_presubmit').unbind('click');
	jQuery(gf + ' #gform_presubmit').click(function(e){

		firstLastEmailValidation();

		setTimeout(function() {

			firstLastEmailValidation();

			var validation_error_check = jQuery(gf + ' .validation_error');

			if(validation_error_check.length <= 0) {

				console.log('No errors, continue to submit Salsa form...');
				document.querySelector("[data-ignite-submit-button]").click();

			} else {

				setTimeout(function(){
					jQuery('html,body').animate({
						scrollTop: jQuery('#field_1_55').offset().top - 132
					},1000);
				},250);

				console.log('Please clear form errors before submitting...');

			}

		}, 250)


	});

	var checkExist = setInterval(function() {

	   if (jQuery('.sli-confirmation-pane').length) {

			console.log('Salsa Form elements detected...');
			console.log('Setting up mutation observer...');

			// Check to see if newsletter field is checked on by default
			// Alter the Salsa form based on what is set in the Gravity Form
			setTimeout(function(){
				if(jQuery(gf_newsletter_field)[0].checked) {
					jQuery(salsa_newsletter_field).prop('checked', true);
				} else {
					jQuery(salsa_newsletter_field).prop('checked', false);
				}
			},2000);

			// Mutation Observer to Watch for Salsa Form Submission Response
			var sli_confirmation_pane = document.querySelector(".sli-confirmation-pane");

			var salsaConfirmationObserverConfig, salsaConfirmationFormSectionObserver;

			salsaConfirmationObserverConfig = {
				attributes: true,
				childList: true,
				characterData: true
			};

			salsaConfirmationFormSectionObserver = new MutationObserver(function (mutations) {
				mutations.forEach(function (mutation) {
					console.log(mutation);
					if (jQuery('.sli-confirmation-pane:contains("Thank You")').length > 0) {
						console.log('Salsa Form Submission Detected...');
						console.log('Submitting Form...');
						document.getElementById("gform_1").submit();
					}
				});
			});

			salsaConfirmationFormSectionObserver.observe(sli_confirmation_pane, salsaConfirmationObserverConfig);

	      clearInterval(checkExist);

	   }

	}, 100); // check every 100ms

	// Error Message

	var checkErrorExist = setInterval(function() {

		if (jQuery('.validation_error').length) {

			jQuery('.validation_error').insertBefore( ".form-begin" );

			clearInterval(checkErrorExist);

		}

	}, 100); // check every 100ms


}


function firstLastEmailValidation() {

	// Page template
	var pt = '.page-template-template-symptoms-assessment-tool';

	// Gravity Form ID
	var gf = pt + ' #gform_1';

	// Salsa Form ID
	var sf = pt + ' #9226c3be-4860-4c3d-b878-77f9d39ebe89';


	// Input fields
	var gf_firstname_field = gf + ' #input_1_56_3';
	var gf_lastname_field = gf + ' #input_1_56_6';
	var gf_email_field = gf + ' #input_1_57';

	// If no first or last name
	if (!jQuery(gf_firstname_field).val() || !jQuery(gf_lastname_field).val()) {

		var error = jQuery('.input_1_56_error');
		var input_container = jQuery('#field_1_56');

		if (error) {
			error.remove();
		}

		if (input_container) {
			input_container.append('<div class="custom_validaation_error input_1_56_error db"><span class="input_1_56_error validation_error f6 w-100">First and Last Name are Required</span></div>');
		}

	} else {
		jQuery('.input_1_56_error').remove();
	}

	// If no first or last name
	if (!jQuery(gf_email_field).val()) {

		var error = jQuery('.input_1_57_error');
		var input_container = jQuery('#field_1_57');

		if (error) {
			error.remove();
		}

		if (input_container) {
			input_container.append('<div class="custom_validaation_error input_1_57_error db"><span class="input_1_57_error validation_error f6 w-100">Email is Required</span></div>');
		}

	} else {
		jQuery('.input_1_57_error').remove();
	}

}